

.playercontainer {
  width: 100vw;
  height: 100vh;
  max-width: none;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(360deg, #7a1212, #15155ade, #00ff635e);
  box-sizing: border-box;
  overflow: hidden;

}




.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.episodeimage {
  width: 70%;
  height: 50%;
}




.episodeimage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.episodedetails {
  height: 20%;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.episodedetails .title {
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 1px;
  line-height: 18px;

  
}

.episodetitle {
  padding: 0px 10px;
  font-size: 12px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  overflow-x: clip;
  width: 80%;

}

.episodetitle p {
  display: block;
  width: 100%;
  transform: translateX(100%);
  animation: move 10s linear infinite;
  color: #ffffff;
}

/* Create the animation */
@keyframes move {
  to {
    transform: translateX(-100%);
  }
}

.text-container {
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(to right,
      transparent,
      black 10%,
      black 90%,
      transparent);
  -webkit-mask-image: linear-gradient(to right,
      transparent,
      black 10%,
      black 90%,
      transparent);
}

.epishare {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 4%;
  height: 30px;
  width: 100%;
}

.epishare img {
  width: 24px;
}

.number {
  color: #ffffffba;
}

.share {
  padding-top: 2%;
}

.episodeaudio {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  padding: 20px 10px;
  border-radius: 55px;
  margin: auto;
  font-family: 'Manorama Malayalam', sans-serif !important;

}

.prev-btn img,
.next-btn img {
  width: 25px;
}

button {
  border-radius: 100px;
  background-color: red;
}

button:disabled {
  opacity: 0.5;
}

.episodeaudio audio {
  padding: 0px 15px;
  width: 100% !important;
  margin-left: 0px !important;
}

@media (max-width:800px) {

  .playercontainer {
    width: 100vw;
    height: 100vh;
    max-width: none;
  }

  .prev-btn img,
  .next-btn img {
    width: 30px;
  }

  .episodeaudio {
    padding: 10px 0px;
  }

  .episodeaudio audio {
    padding: 0px;
  }

  .audio-controls {
    position: fixed;
    width: 90%;
    bottom: 10px;
  }
}

.audio-controls {
  background-color: rgb(0 0 0 / 42%);
  padding: 18px 15px 0px 15px;
  border-radius: 10px;
  margin-top: 20px;
}

.progress-bar {
  width: 100%;
  height: 4px;
  background-color: #4d4d4d;
  margin-bottom: 10px;
  position: relative;
}

.progress {
  height: 100%;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
}

.time-display {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 12px;
  margin-bottom: 10px;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.control-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.play-pause {
  font-size: 48px;
}

.prev-next {
  font-size: 36px;
}

.progress-bar {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

.progress {
  height: 100%;
  background-color: white;
  position: relative;
}

.progress-circle {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.progress-circle {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  right: -6px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.controls img {
  width: 30px;
}

@media (min-width:500px) {
  .playercontainer {
    width: 400px;
    margin: auto;
  }

  .audio-controls {
    width: 90%;
    margin-top: 10px;
  }
}