.storycard {
    display: flex;
    width: 80%;
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    border-radius: 3px;
    height: 250px;
    text-decoration: none;
}



@media (max-width:1000px) {
    .storycard {
        width: 95%;
    }
}

body {
    color: white;
}

.cardimage {
    width: 30%;
}

.cardimage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.carddescription {
    padding: 0px 18px;
    background: linear-gradient(180deg, #403d3dde, #000000);;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;


}

.cardtitle {
    font-size: 22px;
    font-weight: bolder;
    color: #ffffff;
}

.titledesc {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: #e9e9e9;
}

.hashdates {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hsd1 {
    font-size: 15px;
    font-weight: bolder;
    word-spacing: 10px;
}

.hsd2 {
    font-size: 13px;
    color: #ffffffb0;
}

.odd-hashtag{
    color: #d54eff;;
}

.even-hashtag{
    color:#f46b6b;
}


@media (max-width:800px) {

    .storycard {
        flex-direction: column;
        width: 45%;
        height: fit-content;
        margin-bottom: 30px;
        height: 230px;

    }


    .titledesc {
        display: none;
    }

    .hsd2 {
        display: none;
    }

    .hsd1 {
        font-size: 10px;
        margin: 0px;
        color: #d4f18d;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 6px;
    }

    .carddescription {
        padding: 5px 5px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        width: 100%;
        justify-content: space-between;
        height: 65px;
    }

    .cardtitle {
        font-size: 11px;
        font-weight: 400;
        color: #ffffff;
        line-height: 15px;
        letter-spacing: 1px;
    }


    .cardimage {
        width: 100%;
        height: 150px;
    }

    .cardimage img {
        height: 150px;
    }

}