.slick-slider {
    width: 85%;
    margin: auto;
    display: none;
    margin-bottom: 20px;
}

a{
    text-decoration: none;
}

@media (max-width:800px) {
    .slick-slider {
        display: block;
        min-height: 250px;
    }
}

.slidercard {
    display: flex;
    width: 80%;
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    border-radius: 7px;
    height: 250px;
}

@media (max-width:1000px) {
    .slidercard {
        width: 95%;
    }
}

body {
    color: white;
}

.slidercardimage {
    width: 100%;
}

.slidercardimage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slidercarddescription {
    display: flex;
    padding: 0px 18px;
    flex-direction: column;
    justify-content: center;
}

.slider-green-bg {
    background: linear-gradient(136deg,#6f1e1e, #592fe7);
}


.slider-blue-bg {
    background: linear-gradient(44deg, #592fe7, #6f1e1e);
}

.slidercardtitle {
    font-size: 22px;
    font-weight: bolder;
    color: #8a96a7;
}

.slidertitledesc {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
}

.sliderhashdates {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sliderhsd1 {
    font-size: 15px;
    font-weight: bolder;
    word-spacing: 10px;
    color: #e800ff;
}

.sliderhsd2 {
    font-size: 13px;
    color: #ffffffb0;
}


@media (max-width:800px) {

    .slidercard {
        flex-direction: column;
        width: 90%;
        height: fit-content;
        margin-bottom: 30px;
        /* box-shadow: 0px 0px 0px 1px #80ff005d; */

    }


    .slidertitledesc {
        display: none;
    }

    .sliderhsd1,
    .sliderhsd2 {
        display: none;
    }

    .slidercarddescription {
        padding: 5px 5px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        height: 60px;
    }

    .slidercardtitle {
        font-size: 11px;
        font-weight: 400;
        color: #ffffff;
        line-height: 15px;
        letter-spacing: 1px;
    }


    .slidercardimage {
        width: 100%;
        height: 150px;
    }

}